export const LARGER_DISPLAY_WIDTH = "1600px";
export const LARGE_DISPLAY_WIDTH = "1280px";
export const DEFAULT_WIDTH = "980px";
export const TABLET_WIDTH = "768px";
export const MOBILE_WIDTH = "480px";

export const LARGER_DISPLAY_MEDIA_QUERY =
  "@media only screen and (max-width:1600px)";
export const LARGE_DISPLAY_MEDIA_QUERY =
  "@media only screen and (max-width:1280px)";
export const DEFAULT_MEDIA_QUERY = "@media only screen and (max-width:980px)";
export const TABLET_MEDIA_QUERY = "@media only screen and (max-width:768px)";
export const MOBILE_MEDIA_QUERY = "@media only screen and (max-width:480px)";

export const MIN_LARGER_DISPLAY_MEDIA_QUERY = "@media (min-width:1600px)";
export const MIN_LARGE_DISPLAY_MEDIA_QUERY = "@media (min-width:1280px)";
export const MIN_DEFAULT_MEDIA_QUERY = "@media (min-width:980px)";
export const MIN_TABLET_MEDIA_QUERY = "@media (min-width:768px)";
export const MIN_MOBILE_MEDIA_QUERY = "@media (min-width:480px)";
