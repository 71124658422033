import Typography from "typography";
import { MOBILE_MEDIA_QUERY } from "../utils/typography-breakpoint-constants";

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: 1.45,
  scaleRatio: 3,
  headerFontFamily: [
    "Tajawal",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif"
  ],
  bodyFontFamily: ["Tajawal", "Georgia", "serif"],
  bodyColor: "hsla(0,0%,0%,0.9)",
  headerWeight: 900,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    body: {
      letterSpacing: ".03em"
    },
    h1: {
      fontFamily: ["Tajawal", "sans-serif"].join(",")
    },
    h2: {
      fontWeight: 800,
      ...scale(0.8)
    },
    blockquote: {
      ...scale(1 / 5),
      color: "hsla(0,0%,100%,0.6)",
      fontStyle: "italic",
      paddingLeft: rhythm(13 / 16),
      marginLeft: rhythm(-1),
      borderLeft: `${rhythm(3 / 16)} solid hsla(0,0%,100%,0.9)`
    },
    "blockquote > :last-child": {
      marginBottom: 0
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight
    },
    "blockquote cite:before": {
      content: '"— "'
    },
    ul: {
      listStyle: "disc"
    },
    "ul,ol": {
      marginLeft: 0
    },
    [MOBILE_MEDIA_QUERY]: {
      "ul,ol": {
        marginLeft: rhythm(1)
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16)
      }
    },
    "h1,h2,h3,h4,h5,h6": {
      marginTop: rhythm(2)
    },
    h4: {
      letterSpacing: "0.140625em",
      textTransform: "uppercase"
    },
    h6: {
      fontStyle: "italic"
    },
    a: {
      color: "#000000",
      textDecoration: "none"
    },
    "a:hover,a:active": {
      boxShadow: "none"
    },
    "mark,ins": {
      background: "#007acc",
      color: "white",
      padding: `${rhythm(1 / 16)} ${rhythm(1 / 8)}`,
      textDecoration: "none"
    }
  })
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
