export const localizedDate = (unixStampedDate, format) => {
  const date = new Date(unixStampedDate);
  const options = {
    ...(format.includes("d") && { day: "numeric" }),
    ...(format.includes("m") && { month: "long" }),
    ...(format.includes("y") && { year: "numeric" }),
    ...(format.includes("w") && { weekday: "long" })
  };
  return date.toLocaleDateString("en-US", options);
};

const breakpoints = [576, 768, 992, 1280, 1400];

export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);
