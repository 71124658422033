import React, { useState, useContext, useEffect, Fragment } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { LocaleContext } from "../context/locale-context";
import LocalizedLink from "../components/localizedLink";
import useTranslations from "../components/hooks/useTranslations";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { rhythm, scale } from "../utils/typography";
import { MdMenu, MdClose } from "react-icons/md";
import useMediaQuery from "../components/hooks/useMediaQuery";
import { mq } from "../utils/helper";

const Item = styled.li({
  margin: 0,
  paddingLeft: rhythm(2),
  "& a": {
    color: "white",
    fontWeight: 500
  }
});
let image;

function MainMenu({ tr, isRTL }) {
  return (
    <tbody
      css={{
        "& th": {
          textAlign: isRTL ? "right" : "left"
        },
        "& th:last-of-type": {
          paddingRight: "0.96rem"
        }
      }}
    >
      <tr>
        <th
          css={{
            flex: "50%"
          }}
        >
          {isRTL && <Link to="/ar">{tr("Key Findings")}</Link>}
          {isRTL || <Link to="/">{tr("Key Findings")}</Link>}
        </th>
        <th
          css={{
            flex: "50%"
          }}
        >
          <LocalizedLink to="/investigations">
            {tr("Investigations")}
          </LocalizedLink>
        </th>
        <th
          css={{
            flex: "50%"
          }}
        >
          <LocalizedLink to="/data-set">{tr("Data Set")}</LocalizedLink>
        </th>
        <th
          css={{
            flex: "50%"
          }}
        >
          <LocalizedLink to="/method">{tr("methodology")}</LocalizedLink>
        </th>
        <th>
          <Link to={isRTL ? "/" : "/ar"}>{isRTL ? "EN" : "عربي"}</Link>
        </th>
      </tr>
    </tbody>
  );
}

function SecondMenu({ tr }) {
  return (
    <Fragment>
      <Item>
        <a href="https://yemeniarchive.org/en/investigations">
          {tr("Investigations")}
        </a>
      </Item>
      <Item>
        <a href="https://yemeniarchive.org/en/database">{tr("Data Archive")}</a>
      </Item>
      <Item>
        <a href="https://yemeniarchive.org/en/tools_methods">
          {tr("Tools and Methods")}
        </a>
      </Item>
      <Item>
        <a href="https://yemeniarchive.org/en/tech-advocacy">
          {tr("Tech Advocacy")}
        </a>
      </Item>
      <Item>
        <a href="https://yemeniarchive.org/en/about">{tr("About")}</a>
      </Item>
    </Fragment>
  );
}

const assignActiveStyles = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent ? { style: { color: "#FF5400" } } : {};

function MobileHeader({ tr, isRTL }) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const toggleMenu = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <div
      css={{
        position: "sticky",
        top: 0,
        left: 0
      }}
    >
      <div
        css={{
          display: "none",
          backgroundColor: "black",
          alignItems: "center",
          padding: rhythm(0.5),
          [mq[2]]: {
            display: "flex"
          }
        }}
      >
        <div css={{ backgroundColor: "black" }}>
          <a href="https://yemeniarchive.org/en">
            <img
              src="/assets/logos/logo.svg"
              height="100%"
              css={{ marginBottom: 0 }}
            />
          </a>
        </div>
        <h1
          css={{
            color: "white",
            textShadow: "0 2px 4px rgba(0,0,0,0.5)",
            fontSize: "1.4rem",
            margin: "auto",
            textAlign: "center",
            display: "inline",
            [mq[1]]: {
              display: "none"
            }
          }}
        >
          {tr("Medical Facilites Under Fire")}
        </h1>
        <div
          onClick={toggleMenu}
          css={{
            cursor: "pointer",
            color: "white",
            marginLeft: "auto",
            fontSize: "130%"
          }}
        >
          {isNavCollapsed ? <MdClose /> : <MdMenu />}
        </div>
      </div>
      {isNavCollapsed && <MobileNav tr={tr} isRTL={isRTL} />}
    </div>
  );
}

function MobileNav({ tr, isRTL }) {
  return (
    <div
      css={{
        direction: isRTL ? "rtl" : "ltr",
        backgroundColor: "black",
        paddingTop: rhythm(1),
        borderTop: "4px solid #B32C50",
        borderBottom: "3px solid #B32C50",
        Minheight: "75vh"
      }}
    >
      <h1
        css={{
          color: "white",
          fontSize: "1.5rem",
          marginTop: 0,
          textAlign: "center"
        }}
      >
        {tr("Medical Facilities Under Fire")}
      </h1>
      <table
        css={{
          direction: isRTL ? "rtl" : "ltr",
          marginBottom: 0,
          "& a": {
            color: "white"
          },
          "& th:first-of-type": {
            paddingLeft: "1rem"
          },
          "& th": {
            display: "block",
            borderBottom: "none"
          }
        }}
      >
        <MainMenu tr={tr} isRTL={isRTL} />
      </table>
      <br />
      <h2
        css={{
          textAlign: "center",
          fontSize: "1.4rem",
          color: "white",
          marginTop: 0,
          marginBottom: rhythm(0.5)
        }}
      >
        {tr("Yemeni Archive")}
      </h2>
      <ul
        css={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          margin: 0,
          listStyleType: "none",
          "& a": {
            color: "white",
            fontWeight: 700
          },
          "& li": {
            padding: `${rhythm(0.5)} ${rhythm(0.7)}`,
            display: "block"
          }
        }}
      >
        <SecondMenu tr={tr} />
      </ul>
    </div>
  );
}

function DesktopHeader({ isRTL, isScrolled, tr }) {
  return (
    <Fragment>
      <div
        css={{
          position: "sticky",
          top: 0,
          [mq[2]]: {
            display: "none"
          }
        }}
      >
        <div
          css={{
            height: isScrolled ? "10vh" : "50vh",
            backgroundImage: `url(${image.src})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundAttachment: "fixed",
            position: "relative",
            borderBottom: "3px #b32c50 solid",
            direction: isRTL ? "rtl" : "ltr"
          }}
        >
          <div
            css={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(10, 10, 10, 0.3)",
              display: isScrolled && "flex",
              justifyContent: "space-around"
            }}
          >
            <div>
              {isScrolled || <DesktopNav tr={tr} />}
              <div
                css={{
                  maxWidth: isScrolled ? "100%" : "90%",
                  margin: "0 auto",
                  textAlign: "center",
                  height: isScrolled ? "100%" : "auto",
                  display: isScrolled ? "flex" : "block",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <h1
                  css={{
                    color: "white",
                    textShadow: "0 2px 4px rgba(0,0,0,0.5)",
                    margin: isScrolled ? "0" : "auto",
                    marginTop: isScrolled ? "0" : rhythm(2),
                    fontSize: isScrolled ? "2rem" : "4rem",
                    display: isScrolled && "auto"
                  }}
                >
                  {tr("Medical Facilities Under Fire")}
                </h1>
              </div>
            </div>
            <div
              css={{
                display: isScrolled ? "flex" : "block",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <table
                css={{
                  maxWidth: rhythm(27),
                  margin: "0 auto",
                  marginTop: isScrolled ? 0 : rhythm(2),
                  direction: isRTL ? "rtl" : "ltr",
                  borderCollapse: "collapse",
                  fontSize: isScrolled ? "1rem" : "80%",
                  textTransform: "uppercase",
                  color: "white",
                  "& th": {
                    padding: `${rhythm(0.5)} ${rhythm(0.7)}`,
                    textAlign: "center",
                    border: isScrolled ? "none" : "3px solid white"
                  },
                  " & a": {
                    boxSizing: "border-box",
                    color: "white",
                    borderBottom: "3px solid #b32c50"
                  },
                  "& a:hover": {
                    borderBottom: "6px solid #b32c50"
                  }
                }}
              >
                <MainMenu isRTL={isRTL} tr={tr} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function DesktopNav({ tr }) {
  return (
    <nav
      css={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: `0.7rem 4rem`,
        textTransform: "uppercase"
      }}
    >
      <a href="https://yemeniarchive.org/en">
        <img
          src="/assets/logos/logo.svg"
          height="100%"
          css={{ marginBottom: 0 }}
        />
      </a>

      <ul
        css={{
          display: "flex",
          alignItems: "center",
          listStyleType: "none",
          margin: 0
        }}
      >
        <SecondMenu tr={tr} />
      </ul>
    </nav>
  );
}

function Header() {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      file(name: { eq: "headerImage" }) {
        id
        childImageSharp {
          fluid(quality: 75, maxWidth: 1200) {
            src
            ...GatsbyImageSharpFluid
            originalName
            originalImg
          }
        }
      }
    }
  `);
  image = data.file.childImageSharp.fluid;
  const [isScrolled, setIsScrolled] = useState(false);
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const checkNav = () => {
    if (window.pageYOffset > 10) {
      setIsScrolled(true);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkNav);
    }
  }, []);

  // Render both desktop and mobile header, using css to display the right one.
  // see Gatsby issue #11023

  return (
    <Fragment>
      <DesktopHeader isScrolled={isScrolled} isRTL={isRTL} tr={tr} />
      <MobileHeader tr={tr} isRTL={isRTL} />
    </Fragment>
  );
}

export default Header;
