import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { LocaleContext } from "../context/locale-context";
import siteIcon from "../../static/assets/logos/mobile-logo.png";
import CardImage from "../../static/assets/source.jpg";
import useTranslations from "../components/hooks/useTranslations";

const SEO = ({ pathname }) => {
  const locale = useContext(LocaleContext);
  const tr = useTranslations();
  const isRTL = locale === "ar";
  const {
    site: {
      siteMetadata: { siteUrl, title, twitter, description }
    }
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          twitter
          description
        }
      }
    }
  `);

  return (
    <Helmet
      defer={false}
      defaultTitle={tr(title)}
      titleTemplate={`%s | ${tr(title)}`}
    >
      <html lang={locale} />
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="og:url" content={`${siteUrl}/${isRTL ? "ar/" : ""}`} />
      <meta property="og:title" content={tr(title)} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={tr(description)} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={tr(title)} />
      <meta property="og:image" content={`${siteUrl}${CardImage}`} />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta name="twitter:url" content={`${siteUrl}/${isRTL ? "ar/" : ""}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={twitter} />
      <meta name="twitter:image" content={`${siteUrl}${CardImage}`} />
    </Helmet>
  );
};

export default SEO;
